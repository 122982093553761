@import '../../config/variables';

.b-player__progressbar {
  position: relative;
  max-width: 250px;
  width: 100%;
  height: 12px;
  background-color: transparent;
  outline: 1px solid darken($clr-yellow, 10%);
  margin: 1em auto;

  > div {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: $clr-yellow;
    // padding: 10px 0;
  }
}
