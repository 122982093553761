@import '../../config/variables';

.b-podcast-form {
  max-width: 1150px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  background-color: $clr-podcast-bg;
  // align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2.5em .5em;

  &__save {
    display: block;
    position: relative;
    overflow: hidden;
    border: 2px solid $clr-success;
    color: $clr-text;
    text-transform: uppercase;
    font-size: 16px;
    padding: .5em 0;
    border-radius: 3px;
    margin-top: 1em;
    width: 100%;
    text-align: center;
    font-weight: bold;
    background-color: lighten($clr-success, 10%);
  }

  > div {
    width: 32%;
    padding: 1em 2em;
  }
  > div + div {
    border-left: 2px solid darken($clr-yellow, 30%);
  }
}
