.b-categories-list {

  &__item {
    color: #fff;
    border: 1px solid #fff;
    margin: 10px;
    padding: 20px;
  }

  &__child_item {
    margin-left: 30px;
  }

}
