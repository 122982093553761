@import '../../../config/variables';

.b-category-select {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__label {
    color: $clr-muted;
    font-size: 1.1em;
    margin: auto;
  }

  &__dropdown {
    min-width: 200px;
    border: 2px solid $clr-yellow;
    height: 2em;
    background: transparent;
    color: #9dff03;
    font-size: 1em;
    padding: 1em;
    text-align-last: center;
    font-weight: bold;
  }
}
