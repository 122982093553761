@import '../config/variables';

.b-ikfipod-app {
  // 60px for BottomPlayer (h=60)
  padding: 1em 0 $bottom-player-height 0;
}

.b-podcasts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.b-header-container {
  text-align: center;
}

.b-header {
  display: inline-block;
  background-color: $clr-yellow;
  color: $clr-heading;
  position: relative;
  padding: 1em 90px;
  text-align: center;
  margin: 2em auto;

  @media screen and (min-width: 767px) {
    &:after,
    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 100%;
      background-position: center center;
      position: absolute;
      top: 0;
    }
    &:after {
      background-image: url(../assets/header-right.svg);
      right: -10px;
    }

    &:before {
      background-image: url(../assets/header-left.svg);
      left: -10px;
    }
  }

  &__title {
    margin: 0;
    text-transform: uppercase;
    font-weight: lighter;
    font-size: 1.4em;
  }
}
