@import '../../../config/variables';

.b-podcast-form-episode-list {
  overflow-y: auto;
  min-height: 100%;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 1em 0;

    &:after {
      content: '';
      display: block;
      width: 100%;
      margin: 1em auto 0;
      border-bottom: 1px dashed darken($clr-yellow, 25%);
    }
  }
}

.b-podcast-form-episode {
  &__delete_btn, &__update_btn {
    background: transparent;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid $clr-yellow;
    color: $clr-yellow;
    transition: all .3s ease;
    margin: 0 .5em;

    &:hover {
      background-color: $clr-yellow;
      color: #333;
    }
  }

  &__replace_btn {
    color: $clr-yellow;
    width: 20px;
    height: 20px;
    font-size: 2em;
    line-height: .9;
    cursor: grab;
  }

  &__title {
    color: $clr-yellow;
    margin: auto;
  }
}
