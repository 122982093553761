@import './config/variables';

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'PT Sans';
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $clr-bg;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: $clr-yellow;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
}

.u-wysiwyg {
  text-align: left;
  a {
    font-style: italic;
  }
}

.u-input-group {
  margin: .5em 0;
}

.u-input {
  border: 2px solid $clr-yellow;
  color: $clr-input;
  font-size: 1em;
  background-color: transparent;
  padding: 5px 10px;
  line-height: 1.4;
  border-radius: 3px;
  // resize: none;
  width: 100%;
  max-width: 100%;
  outline: none;
}
textarea.u-input {
  min-height: 150px;
}
.u-input::placeholder {
  color: $clr-input-placeholder;
  font-size: .7em;
  padding: .4em;
}

.u-status {
  position: relative;
  border: 1px solid $clr-status-bg;
  border-radius: 3px;
  background-color: $clr-status-bg;
  color: $clr-status;
  padding: 0 5px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: default;
}

.u-explicit {
  position: relative;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  margin: 0 .75em;
  width: 16px;
  height: 16px;
  line-height: 1.2;
  vertical-align: middle;
  border: 1px solid $clr-explicit;
  border-radius: 100%;
  color: $clr-explicit;
  z-index: 3;
  text-align: center;
  cursor: default;

  &:after {
    position: absolute;
    display: block;
    content: "содержит мат или нецензурную лексику";
    bottom: 100%;
    left: 100%;
    font-size: 14px;
    padding: 5px;
    border: 1px solid;
    border-radius: 5px;
    background: $clr-explicit-content-bg;
    color: inherit;
    z-index: 4;
    opacity: .99;
    transform: scale(0);
    transform-origin: left bottom;
    transition: transform .2s ease-in-out;
  }
  &:hover:after {
    transform: scale(1);
  }
}


.window-transition-enter,
.window-transition-exit {
  transition: opacity .4s ease, transform .3s ease;
}
.window-transition-enter,
.window-transition-exit-active {
  opacity: 0;
  transform: scale(0.95) translate(0px, 5px);
}
.window-transition-enter-active {
  opacity: 1;
  transform: scale(1) translate(0, 0);
}

.title-transition-enter,
.title-transition-exit {
  opacity: 0;
  transition: all .8s ease;
}
.title-transition-enter,
.title-transition-exit-active {
  opacity: 0;
  transform: rotateY(180deg) translate(0px, 5px);
}
.title-transition-enter-active {
  opacity: 1;
  transform: rotateY(0) translate(0, 0);
}

.progressbar-transition-enter,
.progressbar-transition-exit {
  transition: transform .3s ease-in-out, opacity .6s ease;
}
.progressbar-transition-enter,
.progressbar-transition-exit-active {
  opacity: 0;
  transform-origin: center;
  transform: scaleY(0);
}
.progressbar-transition-enter-active {
  opacity: 1;
  transform: scaleY(1);
}