@import '../../config/variables';

.b-episode {
  color: #fff;
  font-size: 1.2em;

  &__title {
    color: $clr-heading;
    background-color: $clr-yellow;
    padding: .5em;
    font-size: 1.4em;
    font-weight: lighter;
  }
}