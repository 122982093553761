/*
  Color settings:
*/
$clr-bg: #222222;
$clr-yellow: #ffce00;

$clr-text: #fff;
$clr-heading: #000;

$clr-error: #ff5c5c;
$clr-error-bg: $clr-error;

// $clr-success: #00F58F;
$clr-success: #42b783;

$clr-explicit: #ff5c5c;
$clr-explicit-content-bg: rgba(255, 255, 255, 0.95);

$clr-status: #333;
$clr-status-bg: #999;

$clr-input: #fff;
$clr-input-placeholder: #999;

$clr-muted: #999;

$clr-podcast: $clr-text;
$clr-podcast-bg: #1e1e1e; //#333;

$clr-playback-time: #a3a3a3;

// other
$bottom-player-height: 60px;
$clr-bg-bottom-player: #444;
