@import '../../config/variables';

.b-player-volume {
  position: relative;
  width: 150px;
  height: 100%;
  background-color: transparent;
  margin: auto 1em;

  &:after {
    display: block;
    position: absolute;
    top: 50%;
    border-top: 1px solid darken($clr-yellow, 10%);
    width: 100%;
    content: "";
  }

  &__bar {
    width: 5px;
    position: absolute;
    top: 50%;
    margin-top: -9px;
    height: 18px;
    background-color: $clr-yellow;
    z-index: 2;
    left: 100%;
  }
}