@import '../config/variables';

.b-podcast {
  position: relative;
  background-color: transparent;
  color: $clr-podcast;
  margin: 1em;
  padding: 2em;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: 767px) {
    // width: 30%;
    // max-width: 300px;
    max-width: 30%;
  }

  transition: width .3s ease, background-color .5s ease-in-out;

  &:hover &-btns {
    opacity: 1;
  }
  &-btns {
    position: absolute;
    display: flex;
    flex-direction: row;
    background: #222;
    padding: 10px;
    flex-wrap: wrap;
    width: 120px;
    top: 0px;
    z-index: 3;
    justify-content: space-around;
    opacity: .4;
    transition: opacity .3s ease;

    &--left {
      border-bottom-right-radius: 25px;
      left: 0;
    }
    &--right {
      border-bottom-left-radius: 25px;
      right: 0px;
    }
  }

  &-edit-btn, &-delete-btn {
    display: block;
    width: 40px;
    height: 40px;
    border: 1px solid #42b783;
    background: transparent;
    color: #fff;
    border-radius: 100%;
    font-weight: bold;
    cursor: pointer;
  }
  &-delete-btn {
    border-color: #dd4444;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__meta {
    display: none;
    padding: 2em 1em;
    @media screen and (min-width: 767px) {
      border-left: 1px solid $clr-yellow;
    }
  }

  &__comments {
    display: none;
  }

  $ctx: #{&};

  &.hidden {
    display: none;
  }
  &.maximized {
    display: flex;
    flex-direction: column;
    // width: 64%;
    padding: .5em;
    // position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    z-index: 10;
    overflow-y: scroll;
    max-width: 100%;

    #{$ctx}__title {
      font-size: 1.6em;
      margin: 1em 0;
    }

    #{$ctx}__description {
      display: block;
    }

    #{$ctx}__comments {
      display: block;
      color: $clr-podcast;
      padding: 1em 0;
      margin: 1em 0;
      border-top: 1px solid $clr-yellow;
    }

    #{$ctx}__thumb {
      padding: 0 1em;
      border-radius: 13px;

      &_top {
        position: relative;
        background: transparent;
      }
      &_bottom {
        position: relative;
        background: transparent;
        // display: none;
        @media screen and (min-width: 767px) {
          display: block;
        }
      }
    }
    #{$ctx}__meta, #{ctx}__thumb {
      display: block;
    }

    #{$ctx}__cover {
      width: auto;
      max-width: 300px;
      border-radius: 12px;
    }

    @media screen and (min-width: 767px) {
      #{$ctx}__container {
        flex-direction: row;
        justify-content: space-around;
        padding: 1em;
      }
      #{$ctx}__meta {
        display: block;
        width: 55%;
      }
      #{$ctx}__thumb {
        width: 40%;
      }
    }
  }

  &__thumb {
    position: relative;
    text-align: center;
    overflow: hidden;
    border-radius: 14px;

    &_top {
      position: absolute;
      padding: 10px;
      margin: 0;
      top: 0;
      background-color: rgba(0,0,0,.6);
      width: 100%;
      color: $clr-podcast;
      transition: all .3s ease;
      transform: translateY(-100%);
    }
    &_bottom {
      position: absolute;
      bottom: 0;
      background-color: rgba(0,0,0,.6);
      color: $clr-podcast;
      width: 100%;
      padding: 10px;
      transition: all .3s ease;
      transform: translateY(100%);
    }
  }

  &.maximized, &:hover {
    background-color: $clr-podcast-bg;

    #{$ctx}__thumb {
      &_top, &_bottom {
        transform: translateY(0);
      }
    }
  }

  &__title {
    color: $clr-podcast;
    font-size: 1.2em;
    text-align: center;
    font-weight: normal;
    margin: 0;
    padding: 0;
    & a {
      text-decoration: none;
      color: inherit;
    }
  }

  // &__explicit {
  //   // display: none;
  //   font-size: .6em;
  // }

  &__cover {
    display: block;
    width: 100%;
    margin: auto;
  }
  &__cover_container {
    position: relative;
    // max-width: 300px;
    margin: auto;
  }

  &__description {
    display: none;
    // border-top: 1px solid $clr-yellow;
    padding: 1em 0;
    color: $clr-podcast;
    font-size: 1.2em;
  }

  &.maximized {
    #{$ctx}__maximize_btn:after {
      content: "<";
    }
  }

  &__episode_info {
    border-top: 1px solid $clr-yellow;
  }
}