@import '../config/variables';

.b-bottom-player {
  $ctx: #{&};

  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 10;
  background-color: rgba($clr-bg-bottom-player, 0.9);
  transition: background-color 0.3s ease-in-out, bottom 0.3s ease;
  bottom: -#{$bottom-player-height};

  &:hover {
    background-color: $clr-bg-bottom-player;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: $bottom-player-height;
  }

  &--active {
    bottom: 0px;
  }

  .b-player__btns {
    width: auto;
  }
  .b-player__progressbar {
    margin: auto 20px;
    display: none;
    @media screen and (min-width: 960px) {
      display: block;
    }
  }
  &__progresstime {
    color: $clr-text;
    cursor: default;
    display: none;
    @media screen and (min-width: 960px) {
      display: block;
    }
  }
  &__playing-now {
    margin: auto 20px;
  }
  &__volume {
    display: flex;
    align-items: center;
    margin: auto 20px;
    height: 100%;
    display: none;
    @media screen and (min-width: 960px) {
      display: flex;
    }

    &_icon {
      display: inline-block;
      content: '';
      width: 20px;
      height: 20px;
      background-image: url(../assets/volume.svg);
      background-repeat: no-repeat;
      background-size: 100% 100%;

      #icon * {
        fill: $clr-yellow;
        stroke: $clr-yellow;
        transition: all 0.2s ease-in-out;
      }

      &--muted {
        #icon * {
          fill: $clr-bg-bottom-player;
          stroke: $clr-muted;
        }
      }
    }
  }
}
