.b-logout-btn {
  display: block;
  border: 1px solid #E30000;
  border-radius: 5px;
  padding: .25em .5em;
  color: #E30000;
  text-transform: uppercase;
  background: transparent;
  font-size: 1em;
  font-weight: bold;
}