@import '../config/variables';

@keyframes header-logo-animation-left {
  0% {
    stroke-width: 1;
  }
  50% {
    stroke-width: 1.8;
    transform: translateX(-1px);
  }
  100% {
    stroke-width: 1;
  }
}
@keyframes header-logo-animation-right {
  0% {
    stroke-width: 1;
  }
  50% {
    stroke-width: 1.8;
    transform: translateX(1px);
  }
  100% {
    stroke-width: 1;
  }
}

.b-site-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 2em 0;

  &__logo {
    max-width: 120px;

    &_link {
      display: block;
      width: 100%;
      margin: 1em auto;
      text-align: center;
      @media screen and (min-width: 767px) {
        width: auto;
        margin: 0;
      }
    }

    &.playing {
      #left path {
        animation: header-logo-animation-left 2s infinite ease-in-out;
      }
      #right path {
        animation: header-logo-animation-right 2s infinite ease-in-out;
      }
      #logo path:first-child {
        animation-delay: 1s;
      }
      #logo path + path {
        animation-delay: .5s;
      }
      #logo path + path + path {
        animation-delay: 0s;
      }
    }
  }

  &__menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    margin: auto;
    padding: 0;
    @media screen and (min-width: 767px) {
      margin: 0;
    }

    // min-width: 300px;
    & > li {
      display: inline-block;
      color: $clr-text;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 1.1em;
      margin: 1em;

      a {
        color: inherit;
        text-decoration: none;
        &[target="_blank"] {
          text-decoration: underline;
          border-bottom: 1px solid;
        }
      }
    }
  }
}