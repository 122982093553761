@import '../../../config/variables';

.b-podcast-form-episode-uploader {
  padding: .25em 0;
  margin: 0 0 1.2em 0;

  &__form {
    &:before, &:after {
      content: '';
      display: block;
      width: 100%;
      margin: 1em auto;
      border-bottom: 2px solid $clr-yellow;
    }
  }

  &__container {
    // padding: 0 2em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .u-input {
      margin: .5em 0;
    }
  }

  &__file, &__add {
    display: inline-block;
    position: relative;
    overflow: hidden;
    border: 2px solid $clr-yellow;
    color: $clr-yellow;
    text-transform: uppercase;
    font-size: 14px;
    padding: .5em;
    border-radius: 3px;
    // margin-top: 1em;
    margin: .5em 0;
    width: 100%;
    text-align: center;
  }

  &__file {
    input[type='file'] {
      position: absolute;
      left: -9999px;
    }
  }

  &__add {
    border: 2px solid $clr-yellow;
    background-color: $clr-yellow;
    color: #333;
  }

  &__add {
    display: block;
    width: 100%;
  }

}