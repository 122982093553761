@import '../../../config/variables';

.b-podcast-status {
  position: relative;
  overflow: hidden;
  margin: .5em 0;

  $ctx: #{&};

  &__label {
    color: $clr-muted;
    font-size: 1.1em;

    &:after {
      display: inline-block;
      margin: 0 0 0 .5em;
      color: $clr-error;
      content: 'Нет';
    }
  }

  &__checkbox {
    position: absolute;
    left: -9999px;

    &:checked + #{$ctx}__label:after {
      color: $clr-success;
      content: 'Да'
    }
  }
}