@import '../../config/variables';

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes play-loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

.b-player {
  $ctx: #{&};

  &__playing_now {
    display: block;
    margin: 0.5em auto;
    animation: pulse infinite 4s;
  }

  &__playback {
    position: relative;
    clear: both;
    width: 100%;

    &_time {
      // position: absolute;
      color: $clr-playback-time;
      font-size: 0.7em;
      float: right;
    }
    &_progress {
      // width: 100%;
    }
  }

  &__btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  &__btn {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: transparent;
    color: $clr-yellow;
    margin: 0 0.5em;
    text-align: center;
    border: none;
    cursor: pointer;

    &--play {
      border: 1px solid $clr-yellow;
      background: url(../../assets/play.svg) center center no-repeat;
      background-size: 60% 60%;
      width: 50px;
      height: 50px;
    }
    &--pause {
      border: 1px solid $clr-yellow;
      background: url(../../assets/pause.svg) center center no-repeat;
      background-size: 40% 40%;
      background-color: $clr-yellow;
      width: 50px;
      height: 50px;
    }
    &--next {
      background: url(../../assets/next.svg) center center no-repeat;
      background-size: 60% 60%;
    }
    &--prev {
      background: url(../../assets/prev.svg) center center no-repeat;
      background-size: 60% 60%;
    }
    &--loading {
      animation: play-loading-animation 0.8s infinite ease-in-out;
    }
  }
}
