@import '../../config/variables';

.b-playlist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: .5em 0;

  &-item {
    $ctx: #{&};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin: .25em 0;
    color: $clr-text;
    > * {
      margin: 0 .5em;
    }

    &--playing {
      #{$ctx}__playback {
        &_progress {
          max-width: 250px;
          width: 100%;
        }
      }
    }

    &--selected {
      color: $clr-yellow;
      #{$ctx}__title {
        font-weight: bold;
      }
    }

    &__title {
      color: inherit;
      text-decoration: none;
    }

    &__playback {
      &_time, &_progress {
        margin-left: auto;
        // @media screen and (min-width: 767px) {
        // }
      }
      &_time {
        color: $clr-playback-time;
        font-size: 0.7em;
      }
    }

    // &__explicit {
    //   // margin-left: auto;
    //   font-size: .8em;
    // }
  }
}