@import '../../../config/variables';

.b-preview {
  position: relative;
  width: 250px;
  height: 250px;
  background-size: cover;
  /* background-size: 100% auto; */
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0 auto 1em;

  &:hover .b-preview-upload-btn {
    opacity: 1;
  }
}
.b-preview-upload-btn {
  position: absolute;
  bottom: 10px;
  left: 10px;
  border: 2px solid $clr-podcast-bg;
  background: $clr-yellow;
  border-radius: 3px;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 1em;
  padding: .5em 1em;
  opacity: 0;
  transition: all .3s ease;
}

.b-preview-upload-btn:hover {
  background-color: $clr-podcast-bg;
  color: $clr-yellow;
}

.b-preview-upload-btn input[type='file'] {
  position: absolute;
  left: -9999px;
}