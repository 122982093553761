@import '../../config/variables';

.b-player-playing-now {
  // display: none;
  font-size: 1em;
  color: $clr-text;
  padding: 0 1em;

  // @media screen and (min-width: 767px) {
  //   display: block;
  // }

  &__podcast {
    display: block;
    font-weight: bold;
    text-decoration: none;
    margin: 0.35em 0;
  }
  &__episode {
    display: block;
    font-size: 0.9em;
    text-decoration: none;
    margin: 0.35em 0;
  }
}
